import * as React from "react";
import fetch from "isomorphic-fetch";
import Client from "shopify-buy";
import { toast } from 'react-toastify'

import Cart from '../components/cart'

const client_IN = Client.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_DOMAIN_IN,
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN_IN,
  },
  fetch
);

const client_US = Client.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_DOMAIN_US,
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN_US,
  },
  fetch
);

const defaultValues = {
  cart: [],
  loading: false,
  onOpen: () => {},
  onClose: () => {},
  addVariantToCart: () => {},
  updateLineItemFromCart: () => {},
  deleteLineItemFromCart: () => {},
  getCountry: () => {},
  checkUsBuy:() => {},
  getCartCount:()=>{},
  client_IN,
  client_US,
  checkout: {
    lineItems: [],
  },
};

export const StoreContext = React.createContext(defaultValues);

const isBrowser = typeof window !== `undefined`;
const localStorageKeyForCheckoutId = `shopify_checkout_id`;
const localStorageKeyForIsLocationEnabled = `isLocationEnabled`;

const COUNTRY_OPTIONS = ['IN', 'US'];
const localStorageKeyForCountry = 'nh_country';
const DEFAULT_COUNTRY = 'US';

export const StoreProvider = ({ children }) => {
  const [checkout, setCheckout] = React.useState(defaultValues.checkout);
  const [loading, setLoading] = React.useState(true);
  const [didJustAddToCart, setDidJustAddToCart] = React.useState(false);
  const [cartCount, setCartCount] = React.useState(0);
  const [showCart, setShowCart] = React.useState(false);
  const [country, setCountry] = React.useState(DEFAULT_COUNTRY);
  const [usBuy, setUsBuy] = React.useState(false);
  const [locationEnabled, setLocationEnabled] = React.useState(true);
  const [discountCode, setDiscountCode] = React.useState(null);
  // A function to sset the checkout ID of cart in the local storage
  const setCheckoutItem = (checkout) => {
    localStorage.setItem(localStorageKeyForCheckoutId, checkout.id);
    setCheckout(checkout);
  };
   function locationFind() {
    const userCurrency = localStorage.getItem("nh_country");
    if (!userCurrency) {
       navigator.geolocation.getCurrentPosition(success,error)
    }
  };
  function success(data) {
    const longi = data.coords.longitude;
    const lati = data.coords.latitude;
    localStorage.setItem(localStorageKeyForIsLocationEnabled, true);
    setLocationEnabled(true)
    if(((longi<=98.25) && (longi>=68.7)) && ((lati<=37.6) && (lati>=8.4)) ){
      switchCountry('IN')
    }
    else{
      switchCountry('US')
    }
  }
  function error(){
    const existingLocation = localStorage.getItem(localStorageKeyForIsLocationEnabled);
    if ( existingLocation === null || existingLocation === false ) {
      setLocationEnabled(false)
    }
  }
  const initializeCheckout = async (_country) => {
    const existingCheckoutID = localStorage.getItem(localStorageKeyForCheckoutId);
    // If checkout ID exisits on localstorage, then get the checkout data from shopify
    if (existingCheckoutID && existingCheckoutID !== `null`) {
      try {
        const existingCheckout = await getClient(_country).checkout.fetch(
          existingCheckoutID
        );
        // If the checkout is not yet completed, then use the checkout object
        if (!existingCheckout.completedAt) {
              setCheckoutItem(existingCheckout);
              setLoading(false);
              return;
        }
      } catch (e) {
        localStorage.setItem(localStorageKeyForCheckoutId, null);
      }
    }
    const newCheckout = await getClient(_country).checkout.create();
    setCheckoutItem(newCheckout);
    setLoading(false);
  };

  // A function to return the current country
  const getCountry = () => country;
  // A function to switch countries
  const switchCountry = async (countryCode) => {
    // If current country is same as countryCode, the ignore
    if(getCountry() === countryCode) return
    // If am trying to switch to an unknown country, set it back to default country
    if(!COUNTRY_OPTIONS.includes(countryCode)) {
      localStorage.setItem(localStorageKeyForCountry, DEFAULT_COUNTRY)
      localStorage.removeItem(localStorageKeyForCheckoutId)
      setCountry(DEFAULT_COUNTRY)
      setCartCount(0)
      await initializeCheckout(DEFAULT_COUNTRY)
      return
    }
    localStorage.setItem(localStorageKeyForCountry, countryCode)
    localStorage.removeItem(localStorageKeyForCheckoutId)
    setCountry(countryCode)
    setCartCount(0)
    await initializeCheckout(countryCode)
  }
  React.useEffect(() => {
    if(usBuy) {
      switchCountry('US')
    }
  }, [usBuy])
  
  // A function to get correct client based on country
  // TODO: Support multiple country going forward
  const getClient = (countryCode) => {
    if(countryCode === 'IN') return client_IN;
    return client_US
  }
  React.useEffect(()=>{
    locationFind();
  },[])
  // On first load, set the country to be the default country in local storage
  React.useEffect(() => {
    
    // If local storage already has a country from previous run, use it
    const countrySetOnLocalStorage = localStorage.getItem(localStorageKeyForCountry)
    
    if(countrySetOnLocalStorage && countrySetOnLocalStorage !== null) {
      setCountry(countrySetOnLocalStorage);
      initializeCheckout(countrySetOnLocalStorage);
      return
    }
    
    localStorage.setItem(localStorageKeyForCountry, DEFAULT_COUNTRY);
    initializeCheckout(DEFAULT_COUNTRY);
  }, [])
  
  // Get product by handle shopify
  const getProductByHandle = async (handle, countryCode) => {
    const product = await getClient(countryCode).product.fetchByHandle(handle)
    return product
  }

  // Get variant from product
  const getFirstVariantFromHandle = async (handle, countryCode) => {
    const product = await getProductByHandle(handle, countryCode)
    return product.variants[0]
  }

  // Adds a first variant of the handle to the cart (checkout object)
  const addHandleToCart = async (handle, quantity) => {
    // If cart is loading, don't add more items
    if (loading) return;
    setLoading(true);

    // Get the first variant from the handle
    const firstVariant = await getFirstVariantFromHandle(handle, country)

    const checkoutID = checkout.id;

    // In case you wonder why first variant: Ask Arvind after 6 PM!n
    const lineItemsToUpdate = [
      {
        variantId: firstVariant.id,
        quantity: parseInt(quantity, 10),
      },
    ];

    return getClient(country).checkout
      .addLineItems(checkoutID, lineItemsToUpdate)
      .then((response) => {
        if(discountCode){
          return getClient(country).checkout.addDiscount(response.id, discountCode)
        }
        else{
          return response
        }
      })
      .then((res) => {
        notify('Item added to cart','success')
        setCheckout(res);
        setLoading(false);
        setDidJustAddToCart(true);
        setCartCount(cartCount + 1);
        setTimeout(() => setDidJustAddToCart(false), 3000);
        if (!showCart) toggleCart();
      });
  };
  const notify = (message,type) =>{
    switch(type){
      case 'success':
        toast.success(message);
        break;
      case 'error':
        toast.error(message);
        break;
      case 'info':
        toast.info(message);
        break;
      case 'warn':
        toast.warn(message);
        break;
      default:
        toast(message);
    }
  } 
  // Removed the line item from from the cart (lineItemId)
  const updateLineItemFromCart = async (lineItemId, quantity) => {
    // If cart is loading, don't update any item
    if (loading) return;

    setLoading(true);

    const checkoutID = checkout.id;
    
     // Remove an item from the checkout
     return getClient(country).checkout.updateLineItems(checkoutID, [ {id: lineItemId, quantity}]).then((res) => {
      // Do something with the updated checkout
      setCheckout(res);
      setLoading(false);
      setCartCount(cartCount - 1);
      setDidJustAddToCart(true)
      setTimeout(() => setDidJustAddToCart(false), 3000);
    });
  }

  const deleteLineItemFromCart = async (lineItemId) => {
    // If cart is loading, don't delete any item
    if (loading) return;

    setLoading(true);

    const checkoutID = checkout.id;
    
     // Remove an item from the checkout
     return getClient(country).checkout.removeLineItems(checkoutID, [lineItemId]).then((res) => {
      // Do something with the updated checkout
      setCheckout(res);
      setLoading(false);
      setCartCount(cartCount - 1);
      setDidJustAddToCart(true)
      setTimeout(() => setDidJustAddToCart(false), 3000);
    });
  }

  const getCartCount = () => {
    let sum = 0;
    checkout.lineItems.forEach((item)=>{
      sum+=item.quantity
    })
    return sum;
  }
  // const getCartCount = () =>  cartCount;

  const toggleCart = () => {
    setShowCart(!showCart);
  };

  const cartState = () => showCart;

  const cart = () => checkout.lineItems;

  const total = () => {
    let total = computedCart().reduce(
      (perv, lineItem) => perv + (Number(lineItem.price) * Number(lineItem.quantity)-lineItem.discountAmount),
      0
    );
    return total;
  };

  const buyNow = async () => {
    // If cart is loading, don't update any item
    if (loading) return;
    
    const isBrowser = typeof window !== 'undefined'
    if(isBrowser) {
      if(getCartCount()!==0){
        window.open(checkout.webUrl, '_blank');
        localStorage.setItem(localStorageKeyForCheckoutId, null);
        const newCheckout = await getClient(country).checkout.create();
        setCheckout(newCheckout);
      }
    }
  }
  const computedCart = () => {
    let newCart = []
    checkout.lineItems.forEach((item, index) => {
        newCart.push({
          id: item.id,
          quantity: item.quantity,
          image: item.variant?.image?.src,
          title: item.title,
          price: item.variant?.price?.amount,
          discountApplied : parseFloat(item.discountAllocations[0]?.allocatedAmount.amount) > 0,
          discountAmount: item.discountAllocations[0] && item.discountAllocations[0]?.allocatedAmount? parseFloat(item.discountAllocations[0]?.allocatedAmount.amount):0,
        })
    })
    return newCart
  }

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        getCountry,
        switchCountry,
        addHandleToCart,
        updateLineItemFromCart,
        deleteLineItemFromCart,
        getCartCount,
        cartState,
        toggleCart,
        cart,
        total,
        buyNow,
        usBuy,
        cartCount,
        setUsBuy,
        checkout,
        loading,
        didJustAddToCart,
        setLocationEnabled,
        locationEnabled,
        getFirstVariantFromHandle,
        computedCart,
        discountCode,
        setDiscountCode,
        notify,
        
      }}
    >
      { cartState() && < Cart />}
      { children }
    </StoreContext.Provider>
  );
};