import * as React from "react"
import { StoreProvider, StoreContext } from "./src/context/store-context"
import Cart from "./src/components/cart"


  export function shouldUpdateScroll  ({
    routerProps,
    prevRouterProps,
  }) {
    if(routerProps.location.hash !== "") return true
    setTimeout(() => window.scrollTo(0,0), 10)
    window.scrollTo(0,0)
    return false

  }

export const wrapRootElement = ({ element }) => (
    <StoreProvider>
        {element}
    </StoreProvider>
)


