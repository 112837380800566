import React, { useContext,useEffect } from "react";
import { StoreContext } from "context/store-context";
import CloseImage from "../images/cart_cross.png";
import DeleteImage from "../images/delete-item.svg";
import { TailSpin, ThreeDots } from  'react-loader-spinner'

// write a useEffect to modify the cart list when the country is changed
const Cart = () => {
  const { toggleCart, total, buyNow, updateLineItemFromCart, deleteLineItemFromCart, loading ,getCountry,computedCart,discountCode} = useContext(StoreContext);
  const cart =computedCart()
  const totalWithoutDiscount = () => {
    return cart.reduce((acc, item) => acc + item.price * item.quantity, 0);
  };
  return (
    <div className="cart">
      <div className="cart__header">
        <div className="cart__header__heading">Cart</div>
        <span>
          <img
            className="cart__header__cross"
            src={CloseImage}
            alt="close"
            onClick={toggleCart}
          />
        </span>
      </div>
      <div className="cart__items">
        {cart.map((item, index) => (
          
          <div className="cart__items__item" key={index}>
            <img
              className="cart__items__item__image"
              src={item.image}
              alt=""
            />
            <div className="cart__items__item__data">
              <h1>{item.title}</h1>
              <div style={{display:'flex'}}>

              <h5 className={item.discountApplied?'strike_through':''}>
                {
                  getCountry() === "IN"?
                  new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                }).format(item.price)
                :
                new Intl.NumberFormat("en-IN", {
                style: "currency",
                currency: "USD",
              }).format(item.price)
              }
              </h5>
              {
                item.discountApplied &&
                <h5 style={{marginLeft:'6px'}}>
                  {
                    getCountry() === "IN"?
                    new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                  }).format(item.price - (item.discountAmount/item.quantity))
                  :
                  new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "USD",
                }).format(item.price - (item.discountAmount/item.quantity))
                }
                </h5>
              }
              </div>
              <div className="cart__items__item__data__price">
                <div className="cart__items__item__data__price__left">
                  <div className="cart_modifier" onClick={() => updateLineItemFromCart(item.id, item.quantity - 1)}>-</div>
                  {
                    loading?
                    <TailSpin
                      height="20"
                      width="20"
                      color="#142b6f"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    /> :
                    <h5>{item.quantity}</h5>
                  }
                  <div className="cart_modifier" onClick={() => updateLineItemFromCart(item.id, item.quantity + 1)}>+</div>
                </div>
                <div className="cart__items__item__data__price__right">
                  <img className="cart_delete_item" src={DeleteImage} onClick={() => deleteLineItemFromCart(item.id)}></img>
                </div>
              </div>
            </div>
          </div>
        ))}
        {
          cart.length===0 && 
          <div className="cart__items__empty_placeholder">
            You currently have no products in your cart.
          </div>
        }
      </div>
      <div className="cart__footer">
        <div style={{display:'flex'}}>
          {
            totalWithoutDiscount() !== total() &&
            <h1 className='strike_through'>
              {
                getCountry() === "IN"?
              new Intl.NumberFormat("en-IN", {
                style: "currency",
                currency: "INR",
              }).format(totalWithoutDiscount())
              :
              new Intl.NumberFormat("en-IN", {
                style: "currency",
                currency: "USD",
              }).format(totalWithoutDiscount())
    
            }
            </h1>
          }
          <h1 style={{marginLeft:'8px'}}>
            {
              getCountry() === "IN"?
            new Intl.NumberFormat("en-IN", {
              style: "currency",
              currency: "INR",
            }).format(total())
            :
            new Intl.NumberFormat("en-IN", {
              style: "currency",
              currency: "USD",
            }).format(total())
          }
          </h1>
        </div>
        <button onClick={buyNow}>
          {
           loading ?
            <ThreeDots 
              height="20" 
              width="30" 
              radius="5"
              color="#fff" 
              ariaLabel="three-dots-loading"
              wrapperStyle={{justifyContent: 'center'}}
              wrapperClassName=""
              visible={true}
              /> :
              'Checkout'
          }
        </button>
      </div>
    </div>
  );
};

export default Cart;
